var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("layout-main", [
    _c("div", { staticClass: "page-login" }, [
      _vm.iError
        ? _c("div", { staticClass: "mb-4 text-danger" }, [
            _c("b", [_vm._v(_vm._s(_vm.iError))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.login.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "email" } },
              [_vm._v(_vm._s(_vm.$t("shared.email")))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'",
                },
              ],
              staticClass: "form-control",
              attrs: { id: "email", type: "text", name: "email" },
              domProps: { value: _vm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.email = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _vm.errors.has("email")
              ? _c("span", { staticClass: "validation-error" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.errors.first("email")) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "password" } },
              [_vm._v(_vm._s(_vm.$t("shared.password")))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "input-group" }, [
              (_vm.showPassword ? "text" : "password") === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "password",
                      name: "password",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.password)
                        ? _vm._i(_vm.password, null) > -1
                        : _vm.password,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.password,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.password = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.password = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.password = $$c
                        }
                      },
                    },
                  })
                : (_vm.showPassword ? "text" : "password") === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "password", name: "password", type: "radio" },
                    domProps: { checked: _vm._q(_vm.password, null) },
                    on: {
                      change: function ($event) {
                        _vm.password = null
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "password",
                      name: "password",
                      type: _vm.showPassword ? "text" : "password",
                    },
                    domProps: { value: _vm.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.password = $event.target.value
                      },
                    },
                  }),
              _vm._v(" "),
              _c("div", { staticClass: "input-group-append" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: { type: "button" },
                    on: { click: _vm.togglePasswordShow },
                  },
                  [
                    _c("fa-icon", {
                      staticClass: "text-muted",
                      attrs: {
                        "fixed-width": "",
                        icon: _vm.showPassword ? "eye-slash" : "eye",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.errors.has("password")
              ? _c("span", { staticClass: "validation-error" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.errors.first("password")) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "v-select",
            {
              attrs: { options: _vm.processedSystemLangs, size: _vm.inputSize },
              model: {
                value: _vm.selectedLanguage,
                callback: function ($$v) {
                  _vm.selectedLanguage = $$v
                },
                expression: "selectedLanguage",
              },
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("language.choose")) + "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.login.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("login.text")))]
            ),
            _vm._v(" "),
            false
              ? _c(
                  "a",
                  {
                    staticClass: "ml-3",
                    attrs: { href: _vm.route("page.registration") },
                  },
                  [_vm._v(_vm._s(_vm.$t("shared.registration")))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "ml-3",
                attrs: { href: _vm.route("page.restorePassword") },
              },
              [_vm._v(_vm._s(_vm.$t("login.restorePassword")))]
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }