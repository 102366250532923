<template>
  <layout-main>
    <div class="page-login">
      <div v-if="iError" class="mb-4 text-danger">
        <b>{{ iError }}</b>
      </div>

      <form @submit.prevent.stop="login">
        <div class="form-group">
          <label for="email" class="control-label">{{ $t('shared.email') }}</label>
          <input
            id="email"
            v-model="email"
            v-validate="'required|email'"
            type="text"
            name="email"
            class="form-control"
          />
          <span v-if="errors.has('email')" class="validation-error">
            {{ errors.first('email') }}
          </span>
        </div>
        <div class="form-group">
          <label for="password" class="control-label">{{ $t('shared.password') }}</label>
          <div class="input-group">
            <input
              id="password"
              v-model="password"
              v-validate="'required'"
              :type="showPassword ? 'text' : 'password'"
              name="password"
              class="form-control"
            />
            <div class="input-group-append">
              <button class="btn" type="button" @click="togglePasswordShow">
                <fa-icon class="text-muted" fixed-width :icon="showPassword ? 'eye-slash' : 'eye'" />
              </button>
            </div>
          </div>
          <span v-if="errors.has('password')" class="validation-error">
            {{ errors.first('password') }}
          </span>
        </div>
        <v-select v-model="selectedLanguage" :options="processedSystemLangs" :size="inputSize">
          {{ $t('language.choose') }}
        </v-select>
        <div class="form-group">
          <button class="btn btn-primary" @click.prevent="login">{{ $t('login.text') }}</button>
          <a v-if="false" :href="route('page.registration')" class="ml-3">{{ $t('shared.registration') }}</a>
          <a :href="route('page.restorePassword')" class="ml-3">{{ $t('login.restorePassword') }}</a>
        </div>
      </form>
    </div>
  </layout-main>
</template>

<script>
import { mapGetters } from 'vuex';
import VSelect from '@components/controls/VSelect/VSelect';
import Services from '@scripts/services/services';
import modalMixin from '@mixins/modal';

export default {
  name: 'PageLogin',
  components: { VSelect },
  mixins: [modalMixin],
  props: {
    iError: { type: String, default: '' },
  },
  data() {
    return {
      showPassword: false,
      email: null,
      password: null,
      selectedLanguage: 'default',
      inputSize: 'md',
    };
  },
  computed: {
    ...mapGetters('langs', ['systemLangs']),
    processedSystemLangs() {
      let langs = [{ text: '-', value: 'default' }];
      return langs.concat(this.systemLangs.map((item) => ({ text: item.name, value: item.code })));
    },
  },
  methods: {
    togglePasswordShow() {
      this.showPassword = !this.showPassword;
    },
    login() {
      const errorMessage = this.$t('login.error');

      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        const form = {
          email: this.email,
          password: this.password,
          selectedLanguage: this.selectedLanguage,
        };
        Services.net()
          .post(this.route('login'), null, form)
          .then(
            (data) => {
              if (data.status === 'ok') {
                window.location.href = this.route('home');
              } else {
                this.showMessageBox({ title: this.$t('shared.error'), text: errorMessage });
              }
            },
            () => {
              this.showMessageBox({ title: this.$t('shared.error'), text: errorMessage });
            }
          );
      });
    },
  },
};
</script>

<style>
.pageTitle {
  text-align: center;
}
</style>

<style scoped>
.page-login {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 50px auto 0 auto;
}

.input-group-append .btn {
  border-color: #ced4da;
}
</style>
